<template>
  <Aggregate
    title="Demand Response"
    v-model="demand_response"
    :error-messages="errorMessages"
    :ItemConstructor="demandFactory"
    :allow-edit="false"
    @change="$emit('change')"
  >
    <template #default="{ item, errors }">
      <InputToggleParam
        v-model="item.operating_status"
        :label="schema.operating_status.properties.active.title"
        :hint="schema.operating_status.properties.active.tooltip"
        :error-messages="$utils.getIfExists(errors, ['operating_status'])"
        @change="$emit('change')"
      >
        <template v-slot="{ disabled, param }">
          <InputProfile
            v-model="param.id"
            :hint="schema.operating_status.then.properties.param.properties.id.tooltip"
            profile-type="DAILY_OPERATING_STATUS"
            :disabled="disabled"
            hide-divider
            @change="$emit('change')"
          ></InputProfile>
        </template>
        <!-- <template #below="{param, disabled}"></template> -->
      </InputToggleParam>

      <InputYearly
        v-model="item.power_capacity"
        :label="schema.power_capacity.title"
        :hint="schema.power_capacity.tooltip"
        :error-messages="$utils.getIfExists(errors, ['power_capacity'])"
        :suffix="schema.power_capacity.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.power_capacity.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <InputYearly
        v-model="item.energy_costs"
        :label="schema.energy_costs.title"
        :hint="schema.energy_costs.tooltip"
        :error-messages="$utils.getIfExists(errors, ['energy_costs'])"
        :suffix="schema.energy_costs.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.energy_costs.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <InputYearly
        v-model="item.investment_cost"
        :label="schema.investment_cost.title"
        :hint="schema.investment_cost.tooltip"
        :error-messages="$utils.getIfExists(errors, ['investment_cost'])"
        :suffix="schema.investment_cost.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.investment_cost.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <InputYearly
        v-model="item.maximum_capacity"
        :label="schema.maximum_capacity.title"
        :hint="schema.maximum_capacity.tooltip"
        :error-messages="$utils.getIfExists(errors, ['maximum_capacity'])"
        :suffix="schema.maximum_capacity.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.maximum_capacity.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <InputYearly
        v-model="item.maximum_build"
        :label="schema.maximum_build.title"
        :hint="schema.maximum_build.tooltip"
        :error-messages="$utils.getIfExists(errors, ['maximum_build'])"
        :suffix="schema.maximum_build.unit"
        :num_years="num_years"
        type="number"
        fill
        :fill-value="schema.maximum_build.items.default"
        @change="$emit('change')"
      ></InputYearly>
      <v-text-field
        v-model.number="item.max_reserve_participation"
        :label="schema.max_reserve_participation.title"
        :hint="schema.max_reserve_participation.tooltip"
        :error-messages="$utils.getIfExists(errors, ['max_reserve_participation', 'messages'])"
        :suffix="schema.max_reserve_participation.unit"
        type="number"
        class="my-4"
        @change="$emit('change')"
      ></v-text-field>
      <v-text-field
        v-model.number="item.max_utilization"
        :label="schema.max_utilization.title"
        :hint="schema.max_utilization.tooltip"
        :error-messages="$utils.getIfExists(errors, ['max_utilization', 'messages'])"
        :suffix="schema.max_utilization.unit"
        type="number"
        class="my-4"
        @change="$emit('change')"
      ></v-text-field>
      <v-text-field
        v-model.number="item.ramp_rate"
        :label="schema.ramp_rate.title"
        :hint="schema.ramp_rate.tooltip"
        :error-messages="$utils.getIfExists(errors, ['ramp_rate', 'messages'])"
        :suffix="schema.ramp_rate.unit"
        type="number"
        class="my-4"
        @change="$emit('change')"
      ></v-text-field>
      <InputReserve
        v-model="item.reserve_participation"
        :label="schema.reserve_participation.title"
        :hint="schema.reserve_participation.tooltip"
        :error-messages="$utils.getIfExists(errors, ['reserve_participation'])"
        :reserves="reserves"
        suffix="%"
        type="number"
        @change="$emit('change')"
      ></InputReserve>
    </template>
  </Aggregate>
</template>

<script>
import { CaseModel, schemas } from "data-dictionary";

export default {
  name: "SystemDemandResponse",
  components: {
    Aggregate: () => import("@/components/Aggregate.vue"),
    InputYearly: () => import("@/components/InputYearly.vue"),
    InputReserve: () => import("@/components/InputReserve.vue"),
    InputToggleParam: () => import("@/components/InputToggleParam.vue"),
    InputProfile: () => import("@/components/InputProfile.vue"),
  },
  props: {
    num_years: {
      type: Number,
    },
    demand_response: {
      type: Array,
      required: true,
    },
    reserves: {
      type: Array,
      required: true,
    },
    errorMessages: { type: Object, required: false },
  },
  data() {
    return {
      schema: schemas.filter((v) => v.$id === "demand_response.schema.json")[0].properties,
    };
  },
  computed: {},
  methods: {
    demandFactory: CaseModel.DemandResponse,
  },
};
</script>

<style></style>
